<template>
  <main id="pageWrapper">
    <section id="loginWrapper">
      <img ref="logoImage" src="@/assets/logo.svg" alt="logo" />
      <!-- <h2 id="title">
        Welcome to the upload portal for Cain&amp;AbelDDB's Preview Server
      </h2> -->
      <!-- <h5 id="subtitle">Login below to upload your latest work</h5> -->
      <form v-on:submit.prevent>
        <label for="email">Email Address</label>
        <input type="email" id="email" v-model="email" />
        <label for="password">Password</label>
        <input type="password" name="" id="password" v-model="password" />
        <p id="errorMessage" v-if="displayErrorModal && errorMessage !== ''">
          {{ errorMessage }}
        </p>
        <button :disabled="!email || !password" @click="login">Login</button>
      </form>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      email: null,
      password: null,
      displayErrorModal: false,
      errorMessage: "",
    };
  },
  methods: {
    async login() {
      try {
        const { data } = await this.$http.post("/api/auth/login", {
          email: this.email,
          password: this.password,
        });

        // Note: AccessToken is now set in interceptors
        await this.$store.commit("auth/setUserData", data);

        this.$router.push("/cp");
      } catch (e) {
        this.errorMessage =
          "Please contact a dev or refresh the page and try again.";
        this.password = null;
        this.displayErrorModal = true;
        console.error(e);
      }
    },
  },
};
</script>


<style scoped>
#pageWrapper {
  width: 100%;
  height: 100vh;
}

#loginWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

img {
  width: 400px;
  margin-bottom: 50px;
}

#subtitle {
  margin-top: 0;
}

#loginWrapper > form {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

label {
  margin-bottom: 0.4rem;
}

input {
  width: 100%;
  padding: 0.4rem;
  margin-bottom: 1rem;
}

button {
  width: 100px;
  padding: 0.4rem 2rem;
}

#errorMessage {
  color: red;
}
</style>