import axios from 'axios'
import store from '../store';
// import router from '../router'
import { _getPathAPI } from '../utils/helpers';

const axiosInstance = axios.create({
  baseURL: _getPathAPI(),
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.defaults.timeout = 20000;
axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await store.getters['auth/getAccessToken'];
    config.headers["Authorization"] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (res) => { 
    // If Header Contains Access Token store it
    if (res.headers["access-token"]) {
      console.log('new access token from headers')
      store.commit('auth/setAccessToken', res.headers["access-token"])
    }
    // If Header Contains User Data store it from base64 (happens via authorise check)
    if (res.headers["user-data-update"]) {
      console.log('new user data from headers')
      const base64Header = res.headers["user-data-update"];
      const decodedString = atob(base64Header);
      const userData = JSON.parse(decodedString);
      store.commit('auth/setUserData', userData)
      
    }
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/" && err.response) {

      // 401 Access Token was expired Get a New One
      if (err.response.status === 401 && !originalConfig._retry) {
        console.log("INTERCEPTOR ---> Getting New Token")
        originalConfig._retry = true;
        // Handle get new refresh token
        try {
          const res = await axiosInstance.get("/api/auth/refresh-token");
          store.commit('auth/setAccessToken', res.data.accessToken)
          store.commit('auth/setUserData', res.data.userData)
          return axiosInstance(originalConfig);
        } catch (_error) {
          console.error('User has no valid refresh token after 401');

          // // Prompt Login Overlay if not on dashboard
          // if (router.currentRoute.path !== "/") {
          //   store.commit('auth/toggleShowRelogModal', true)
          // } 

          return Promise.reject(_error);
        }
      }

    }
    return Promise.reject(err);
  }
);

export default axiosInstance;
