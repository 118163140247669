import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// HTTP Services
import api from "./services/api";

const app = createApp({
  extends: App,
  // mixins: [globalMixin],
  // components: {
    
  // }
})

// Extensions
app.config.globalProperties.$http = api;


// Register App
app.use(store).use(router).mount('#app');