import store from '../store'

// Path Helpers
export const _getPathAPI = () => {
  return process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://api-previews.adamandeveddbstudios.com"
}

export const _getPathCMS = () => {
  return process.env.NODE_ENV === "development" ? "http://localhost:1337" : "http://campaigns.cainandabelddb.com:3000";
}


export const _getUserDataField = (field, parent = null) => {
  const userData = store.getters['auth/getUserDetails'];
  return parent ? userData[parent][field] : userData[field];
}



