import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'

import store from '../store';
import AuthService from '../services/auth.service';

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/cp',
    name: 'cp',
    component: () => import(/* webpackChunkName: "ControlPanel" */ '../views/ControlPanel.vue')
  },
  // {
  //   path: '/password-reset',
  //   name: 'password-reset',
  //   component: () => import(/* webpackChunkName: "ControlPanel" */ '../views/PasswordReset.vue')
  // },
  {
    path: '/editor/:clientId',
    name: 'campaigns',
    component: () => import(/* webpackChunkName: "campaigns" */ '../views/CampaignsPanel.vue')
  },
  {
    path: '/editor/:clientId/:campaignId/:campaignUUID',
    name: 'Editor',
    component: () => import(/* webpackChunkName: "editor" */ '../views/EditorPanel.vue')
  },
  // Public Preview URL
  {
    path: '/preview/:campaignUUID/:campaignId',
    name: 'Preview',
    component: () => import(/* webpackChunkName: "preview" */ '../views/PreviewView.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "preview" */ '../views/AdminView.vue')
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// Route Guarding

const isUserAuthenticated = async () => {
  const accessToken = await store.getters['auth/getAccessToken'];
  const userDetails = await store.getters['auth/getUserDetails'];
  return accessToken && userDetails ? true : false;
}


router.beforeEach(async (to, from, next) => {


  const publicPages = ['/', '404', '/reset-password', '/register', '/forgot-password', '/enquire', '/account/register'];
  const authRequired = !publicPages.includes(to.path);
  const authenticated = await isUserAuthenticated();

  //
  // Authorisation Guard
  //

  // Login guard if authenticated
  if (to.path === '/' && authenticated) {
    next('/cp');
  }

  // Login Guard if not currently authenticated but has refresh token
  if (to.path === '/' && !authenticated) {
    console.log('Attempting Relogin from Login Page')
    try {
        await AuthService.attemptRelog();
        console.log('Router: Succesful relog')
        next('/cp');
    } catch (e) {
      console.log('Auth Service: Relog Attempt Failed', e);
    }
  }

  // Authentication Guarding and Relogging
  if (authRequired) {
    if (to.path.startsWith('/preview/')) {
      next();
    }
    if (!authenticated) { // Not logged in
      try {
        await AuthService.attemptRelog();
        console.log('DEBUG Router: Succesful relog')
        next();
      } catch(e) {
        console.log('Auth Service: Relog Attempt Failed', e);
        next('/')
      }
    } else { // already logged in
      console.log('DEBUG Router: Already logged in')
      next();
    }
  } else { // No Auth Required
    console.log('DEBUG Router: No Auth Required');
    next();
  }

});









export default router
