export default {
  namespaced: true,
  state: {
    userDetails: null,
    accessToken: null,
    showRelogModal: false
  },
  getters: {
    getUserDetails: state => {
      return state.userDetails;
    },
    getAccessToken: state => {
      return state.accessToken;
    },
    getRelogModalStatus: state => {
      return state.showRelogModal;
    },
  },
  mutations: {
    setUserData(state, data) {
      state.userDetails = data;
    },
    setAccessToken (state, data) {
      state.accessToken = data
    },
    toggleShowRelogModal (state, data) {
      state.showRelogModal = data
    },
  },
  actions: {},
}
