<template>
  <nav
    id="navbar"
    v-if="this.$route.name !== 'login' && this.$route.name !== 'Preview'"
  >
    <div class="navInner">
      <div class="navLeft">
        <img class="logoMain" src="../assets/logo.svg" alt="" />
      </div>
      <div class="navRight">
        <router-link to="/cp">Home</router-link> |
        <a @click="logout()">Logout</a>
      </div>
    </div>
  </nav>
</template>

<script>
import AuthService from "@/services/auth.service.js";

export default {
  data() {
    return{
      events: ["dragenter", "dragleave", "dragover", "drop"]
    }
  },
  methods: {
    navScroll() {
      const navbar = document.getElementById("navbar");

      if(window.scrollY > 0){
        navbar.classList.add('dark')
      } else {
        navbar.classList.remove('dark')
      }
    },
    async logout() {
      try {
        await AuthService.logout();
      } catch (e) {
        console.error(e);
      }
    },
  },
  created() {
      window.addEventListener('scroll', this.navScroll);
  },
};
</script>

<style scoped>

nav a.router-link-exact-active {
    color: black;
    text-decoration: none;
}

nav {
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid black;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

nav {
  padding: 1rem 0;
  background-color: white;
  z-index: 3;
  transition: background-color 0.2s ease-out, padding 0.2s ease-out;
}

nav.dark {
  background-color: black;
  padding: 0.3rem 0;
}

nav.dark router-link,
nav.dark a {
  color: white;
}

nav.dark .logoMain{
  filter: brightness(0) invert(1);
}

nav .navInner {
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoMain {
  width: 200px;
}
</style>
