import api from './api';
import store from '@/store';
import router from '@/router'


class AuthService {
  async attemptRelog() {
    console.log('Auth Service: Attempting to revalidate login');
    await api.get('/api/auth/revalidate-login');
  }

  async logout() {
    // Deletes access and refresh token
    await api.delete('/api/auth/logout');
    // Delete Store Data
    store.commit('auth/setAccessToken', null);
    store.commit('auth/setUserData', null);
    store.commit('auth/setPermissionsSchema', null);
    // Redirect To Login
    router.push('/');
  }
}

export default new AuthService();
